<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.coordinator') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/members" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/members" class="breadcrumbs-link"> {{ $t('labels.members') }}</router-link>
            </div>
          </div>

          <div class="subheader-right">
            <v-guard :permissions="['members-toolbar-save-search']">
              <button @click="$refs.memberlist.openSaveSearch()" class="btn btn-sm btn-light mr-3"><i
                  class="fas fa-save"></i> {{ $t('labels.save-search') }}
              </button>
            </v-guard>
            <v-guard :permissions="['members-toolbar-email']">
              <button @click="$refs.memberlist.openSendMail()" class="btn btn-sm btn-light mr-3">
                <i class="fas fa-envelope"></i> {{ $t('labels.send-email') }}
              </button>
            </v-guard>
            <v-guard :permissions="['members-toolbar-export']">
              <b-dropdown v-if="!isDownloading" id="dropdown-1">
                <template v-slot:button-content><i class="fas fa-download"></i> {{ $t('labels.export') }}</template>

                <b-dropdown-group id="dropdown-group-1" header="PDF">
                  <b-dropdown-item-button @click="$refs.memberlist.exportResults('members', 'pdf')">{{ $t('labels.general-information') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="$refs.memberlist.exportResults('address', 'pdf')">{{ $t('labels.address-labels') }}
                  </b-dropdown-item-button>
                </b-dropdown-group>
                 <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-2" header="Excel">
                    <b-dropdown-item-button @click="$refs.memberlist.exportMemberResultsEstimate('members')">{{ $t('labels.all-info') }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="$refs.memberlist.exportMemberResultsEstimate('members_address_list')">{{ $t('labels.address-list') }}
                    </b-dropdown-item-button>
                    <!-- <b-dropdown-item-button @click="$refs.memberlist.exportMemberResultsEstimate('address')">Adressetiketter
                </b-dropdown-item-button> -->
                  </b-dropdown-group>
              </b-dropdown>
            </v-guard>
            <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> {{ $t('labels.wait') }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <member-list
            ref="memberlist"
            @downloadStatus="toggleIsDownloading"
            :facets="facets"
            :query="query"
            :fields="fields"
            :title="this.$t('labels.coordinator')"
        ></member-list>
      </div>
    </div>
    <manual />
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import MemberList from '@/components/lists/member/MemberList';
import Manual from '@/components/Manual';

export default {
  components: {
    LayoutMain,
    MemberList,
    Manual
  },
  methods: {
    toggleIsDownloading(status) {
      this.isDownloading = status;
    },
  },
  data() {
    return {
      isDownloading: false,
      facets: [
        {
          level: 1,
          id: 'countySubFilter',
          nestedField: 'county',
          field: 'county.label.raw',
          label: this.$t('labels.county'),
          placeholder: this.$t('labels.county'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 1,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centres'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
          permission: null
        },
        {
          level: 1,
          id: 'localofficeFilter',
          field: 'localoffice.raw',
          label: this.$t('labels.local-office'),
          placeholder: this.$t('labels.local-office'),
          type: 'list',
          permission: null
        },
        {
          level: 1,
          id: 'categoryFilter',
          field: this.$field('category')+'.raw',
          label: this.$t('labels.category'),
          placeholder: this.$t('labels.category'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 1,
          id: 'nameFilter',
          field: 'full_name',
          label: this.$t('labels.name'),
          placeholder: `${this.$t('labels.name')}..`,
          type: 'search',
          permission: null
        },
        {
          level: 1,
          id: 'phoneFilter',
          field: ['phone_1', 'phone_2', 'mobile', 'mobile_office'],
          label: `${this.$t('labels.phone')} #`,
          placeholder: `${this.$t('labels.phone')} #..`,
          type: 'search',
          permission: null
        },
        {
          level: 1,
          id: 'idFilter',
          field: 'id_searchable',
          label: this.$t('labels.member-number'),
          placeholder: `${this.$t('labels.member')} #..`,
          type: 'search',
          permission: null
        },
        {
          level: 2,
          id: 'genderSubFilter',
          field: this.$field('gender')+'.raw',
          label: this.$t('labels.gender'),
          placeholder: this.$t('labels.gender'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'coordinatorSubFilter',
          nestedField: 'coordinator',

          field: this.$field('coordinator.label')+'.raw',
          label: this.$t('labels.coordinator-type'),
          placeholder: this.$t('labels.coordinator-type'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'typeSubFilter',
          field: this.$field('type')+'.raw',
          label: this.$t('labels.non-profit-employee'),
          placeholder: this.$t('labels.non-profit-employee'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'volunteerSubFilter',
          field: this.$field('volunteer') + '.raw',
          label: this.$t('labels.supporter-status'),
          placeholder: this.$t('labels.supporter-status'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'supportSubFilter',
          nestedField: 'support',
          field: this.$field('support.label')+'.raw',
          label: this.$t('labels.support-type'),
          placeholder: this.$t('labels.support-type'),
          type: 'list',
          small: true,
          permission: null
        },
        {
          level: 2,
          id: 'activeMinervaFilter',
          field: 'active_minerva.raw',
          label: this.$t('labels.users-minerva'),
          placeholder: this.$t('labels.users-minerva'),
          type: 'list',
          permission: null,
        },
        {
          level: 2,
          id: 'courtSubFilter',
          nestedField: 'courts',
          field: 'courts.label.raw',
          label: this.$t('labels.court'),
          placeholder: this.$t('labels.court'),
          type: 'list',
          small: true,
          permission: null
        }
      ],
      query: {
        query: {
          bool: {
            must: [
              {
                nested: {
                  path: 'coordinator',
                  query: {
                    exists: {
                      field: 'coordinator.label',
                    },
                  },
                },
              },
            ],
          },
        },
      },
      fields: [
        {
          key: 'id',
          sort_field: 'id',
          label: '#',
          width: 6,
        },
        {
          key: 'first_name',
          sort_field: 'first_name.raw',
          label: this.$t('labels.first-name'),
          width: 11,
        },
        {
          key: 'last_name',
          sort_field: 'last_name.raw',
          label: this.$t('labels.last-name'),
          width: 11,
        },
        {
          key: 'office',
          sort_field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          width: 12,
        },
        {
          key: this.$field('coordinator_list'),
          sort_field: this.$field('coordinator_list')+'.raw',
          label: this.$t('labels.coordinator'),
          width: 14,
        },
        {
          key: this.$field('type'),
          sort_field: this.$field('type')+'.raw',
          label: this.$t('labels.non-profit-employee'),
          width: 14,
        },

        {
          key: 'mobile_office',
          sort_field: 'mobile_office.raw',
          label: this.$t('labels.mobile-business'),
          width: 12,
        },
        {
          key: 'email',
          sort_field: 'email.raw',
          label: '',
          width: 5,
        },
      ],

      isLoading: false,
    };
  },
};
</script>
